import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import BadgeIcon from "@mui/icons-material/Badge";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import i18n from "../../i18n";
import AddToDriveIcon from "@mui/icons-material/AddToDrive";
import { convertMessageCodeToMessage as convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import { DropzoneArea } from "react-mui-dropzone";
import {
  addFile,
  getFileDetail,
  editFileDetail,
} from "../../services/file.service";
import config from "../../config";
import { getCategoriesData } from "../../services/dashboard.service";

class AddEditFile extends React.Component {
  constructor(props) {
    super(props);
      this.category = props?.params?.category
      this.subCategory = props?.params?.subcategory
    this.state = {
      mode: props?.params?.asset_id ? "edit" : "add",
      category: props?.params?.category,
      sub_category: props?.params?.subcategory,
      asset_id: props.params?.asset_id,
      fileDetails: {},
      loading: false,
      errors: {},
    };
    this.role=localStorage.getItem('role');

  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getFileData();
    }
    await this.getCategoryDetails();
  }

  async getFileData() {
    this.setState({
      loading: true,
      tableLoader: true,
    });
    let response = await getFileDetail(this.state.asset_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error
          ? i18n.t(convertMessageCodeToMessage(response?.error))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.file) {
      let fileData = response.file;
      await this.setState({
        fileDetails: {
          ...fileData,
        },
        loading: false,
      });
    }
  }

  async saveFile() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });
    let payload = new FormData();
    let files = [...this.state?.fileDetails?.files];
    files?.map((file, idx) => payload.append("file" + idx, file));
    if (this.state?.fileDetails?.category) {
      payload.append("category", this.state?.fileDetails?.category?.value);
    }

    if (this.state?.fileDetails?.sub_category?.value) {
      payload.append(
        "sub_category",
        this.state?.fileDetails?.sub_category?.value
      );
    }else{
      payload.delete("sub_category")
    }
    if (this.state.mode === "add") {
      let response = await addFile(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error
            ? i18n.t(convertMessageCodeToMessage(response?.error))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.message || i18n.t("file_added_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editFileDetail(this.state.asset_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error
            ? i18n.t(convertMessageCodeToMessage(response?.error))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.message || i18n.t("file_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }

  goBack() {
    this.props.navigate(-1);
  }

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors?.errors };

    if (field == null || field == "category") {
      if (
        !this.state?.fileDetails?.category?.value ||
        this.state.fileDetails?.category?.value == ""
      ) {
        error["category"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["category"];
      }
    }

    if (
      (this.state?.fileDetails?.category &&
        this.state?.subCategoryOptions?.length > 0 &&
        field == null) ||
      field == "sub_category"
    ) {
      if (
        !this.state?.fileDetails?.sub_category?.value ||
        this.state.fileDetails?.sub_category?.value == ""
      ) {
        error["sub_category"] = i18n.t("this_field_is_required");
      } else {
        delete error["sub_category"];
      }
    }
    if (field == null || field == "files") {
      if (
        !this.state?.fileDetails?.files ||
        this.state.fileDetails?.files.length === 0
      ) {
        error["files"] = i18n.t("this_field_is_required");
      } else {
        delete error["files"];
      }
    }

    this.setState({
      errors: error,
    });

    return error;
  }

  async getCategoryDetails() {
    this.setState({
      pageLoader: true,
    });

    let categoryData = await getCategoriesData();
    if (categoryData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: categoryData?.error
          ? i18n.t(convertMessageCodeToMessage(categoryData?.error))
          : categoryData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let tempOptions = categoryData.categories?.map((category) => {
      let temp = {};
      if (category?.sub_categories) {
        temp = {
          label: category?.name,
          value: category?.key,
          sub_categories: category?.sub_categories,
        };
      } else {
        temp = { label: category?.name, value: category?.key };
      }
      return temp;
    });
    let tempCategory = {};
    let tempSubCategory = {};
    let tempSubCategoryOptions = [];
    if (this.state?.category) {
      categoryData.categories?.map((category) => {
        if (category?.key === this.state?.category) {
          if (category?.sub_categories) {
            tempCategory = {
              ...category,
              label: category?.name,
              value: category?.key,
            };
            tempSubCategoryOptions = category?.sub_categories?.map(
              (sub_category) => {
                return { label: sub_category?.name, value: sub_category?.key };
              }
            );
          } else {
            tempCategory = { label: category?.name, value: category?.key };
          }
        }
      });
    }
    if (this.state?.sub_category) {
      tempCategory?.sub_categories?.map((subCategory) => {
        if (subCategory?.key === this.state?.sub_category) {
          tempSubCategory = {
            label: subCategory?.name,
            value: subCategory?.key,
          };
        }
      });
    }
    this.setState({
      pageLoader: false,
      fileDetails: {
        ...this.state?.fileDetails,
        category: tempCategory,
        sub_category: tempSubCategory,
      },
      categoryOptions: tempOptions,
      subCategoryOptions: tempSubCategoryOptions,
    });
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_file")} | BPFM </title>
        </Helmet>

        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/files/view");
              }}
            >
              <AddToDriveIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("files")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {this.state.mode === "add"  && (
                <>
                  <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {i18n.t("add")}
                </>
              )}
            </Typography>
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  options={
                    !this.state.categoryOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.categoryOptions
                  }
                  disabled={this.category}
                  onChange={async (option, value) => {
                    let tempOptions = value?.sub_categories?.map((category) => {
                      return { label: category?.name, value: category?.key };
                    });
                    await this.setState({
                      fileDetails: {
                        ...this.state.fileDetails,
                        category: value
                          ? { label: value?.label, value: value?.value }
                          : { label: "", value: "" },
                        sub_category: {},
                      },
                      subCategoryOptions: tempOptions,
                    });
                    await this.validateField("category");
                  }}
                  value={this.state?.fileDetails?.category?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("category")}
                      error={this.state?.errors?.category ? true : false}
                      helperText={this.state?.errors?.category}
                    />
                  )}
                />
              </Stack>
            </Grid>

            {this.state?.fileDetails?.category?.value &&
              this.state?.subCategoryOptions?.length > 0 && (
                <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
                    <Autocomplete
                      disablePortal
                      disabled={this.subCategory}
                      options={
                        !this.state.subCategoryOptions
                          ? [{ label: "Loading...", id: 0 }]
                          : this.state.subCategoryOptions
                      }
                      onChange={async (option, value) => {
                        await this.setState({
                          fileDetails: {
                            ...this.state.fileDetails,
                            sub_category: value
                              ? { label: value?.label, value: value?.value }
                              : { label: "", value: "" },
                          },
                        });
                        await this.validateField("sub_category");
                      }}
                      value={this.state?.fileDetails?.sub_category?.label || ""}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label={i18n.t("sub_category")}
                          error={
                            this.state?.errors?.sub_category ? true : false
                          }
                          helperText={this.state?.errors?.sub_category}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              )}
          </Grid>
          <br />
          <Grid item xs={12}>
            <Stack>
              <DropzoneArea
                showFileNames={true}
                showFileNamesInPreview={true}
                useChipsForPreview={true}
                filesLimit={200}
                previewGridProps={{ item: ".MuiGrid-grid-xs-2" }}
                maxFileSize={500000000}
                showAlerts={false}
                onChange={async (files) => {
                  await this.setState({
                    fileDetails: {
                      ...this.state.fileDetails,
                      files,
                    },
                  });
                }}
              />
              {this.state?.errors?.files && (
                <FormHelperText style={{ color: "red" }}>
                  {this.state?.errors?.files}
                </FormHelperText>
              )}
            </Stack>
          </Grid>
          <br />
          { this.role!=='viewer' && <Grid item xs={12} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveFile();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_file")
                  : i18n.t("update_file")}
              </Button>
            </LoadingButton>
          </Grid>}
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditFile {...props} navigate={navigate} params={params} />;
}
