import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getFilesList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/assets' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getFileDetail = async (fileId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/assets/' + fileId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addFile = async (payload) => {
    try {
        const response = await axios.post(config.SERVER_BASE_URL +`/assets/upload`,
            payload,
            {headers:{token:localStorage?.getItem('token')}}
        );
        return response.data;
    } catch (error) {
        return error;
    }
};
const editFileDetail = async (fileId, payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.patch( config.SERVER_BASE_URL + '/assets/' + fileId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteFile = async (fileId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.SERVER_BASE_URL + '/assets/' +  fileId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const downloadFile = async (fileId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/assets/' +  fileId + "/download",  { headers: headers ,responseType: 'blob'});
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export { getFilesList, getFileDetail  , addFile , editFileDetail  , deleteFile ,downloadFile};
