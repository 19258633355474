import React from "react";
import { Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress } from "@mui/material";
import i18n from "../../i18n";
import { verifyEmail } from "../../services/auth.service";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        loading:false
    };
    this.otp = props?.params?.token;
  }

  async componentDidMount() {
    await this.verifyEmailToken();
  }

  verifyEmailToken = async () => {
    this.setState({
      loading: true,
    });

    const payload = {
      otp: this.otp,
    };
    let verifyEmailResponse = await verifyEmail(payload);
    if (verifyEmailResponse.status === config?.successCode) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: i18n.t("success"),
        text:
          verifyEmailResponse?.data?.message ||
          i18n.t("verification_successful_please_login"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
      this.setState({
        loading: false,
      });
      this.props.navigate("/login");
      return;
    }
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: verifyEmailResponse?.data?.error
          ? i18n.t(
              convertMessageCodeToMessage(
                verifyEmailResponse?.data?.error
              )
            )
          : verifyEmailResponse?.data?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        loading: false,
      });
      this.props.navigate("/login");
      return;
  };

  render() {
    return (
      <React.Fragment>
        <LinearProgress/>
        <Dialog
          open={true}
        //   onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {i18n.t('verification_in_progress_please_do_not_close_the_window')}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}
// Wrap and export
export default function (props) {
    const navigate = useNavigate();
    const params = useParams();
  return <VerifyEmail {...props} navigate={navigate} params={params} />;
}
