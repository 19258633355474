import { Navigate, useRoutes } from 'react-router-dom';

import AdminLayout from './layouts/admin';

import Login from './pages/LoginScreens/Login';
import Page404 from './pages/Page404';

import Dashboard from './pages/Dashboard';

import Files from './pages/Files/Files';

import Profile from './pages/Profile';
import SignUp from './pages/LoginScreens/RegisterUser';
import ForgotPassword from './pages/LoginScreens/ForgotPassword';
import VerifyEmail from './pages/LoginScreens/VerifyEmail';
import ResetPassword from './pages/LoginScreens/ResetPassword';
import SimpleLayout from './layouts/simple/SimpleLayout';
import SubCategories from './pages/SubCategories/SubCategories';
import Users from './pages/Users/Users';
import AddEditUser from './pages/Users/AddEditUser';
import AddFile from './pages/Files/AddFile';
import ViewFile from './pages/Files/ViewFile';
import Settings from './pages/Settings/Settings';
import AddEditCategorySubCategory from './pages/Settings/AddEditCategorySubCategory';
export default function Router() {
  let role = localStorage.getItem('role');
  const routes = useRoutes([
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'signup',
      element: <SignUp />,
    },
    {
      path: 'verify-email/:token',
      element: <VerifyEmail />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: 'reset-password/:token',
      element: <ResetPassword />,
    },
    {
      path: '/dashboard',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/dashboard/view" />, index: true },
        { path: 'view', element: <Dashboard /> },
        { path: ':category', element: <SubCategories /> },
        { path: ':category/files', element: <Files /> },
        { path: ':category/:subcategory/files', element: <Files /> },
      ]
    },
    {
      path: '/users',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/users/view" />, index: true },
        { path: 'view', element: ['admin'].includes(role) ? <Users /> : <Navigate to="/dashboard" /> },
        { path: ':user_id', element: ['admin'].includes(role) ? <AddEditUser /> : <Navigate to="/dashboard" /> },
      ]
    },
    {
      path: '/files',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/files/view" />, index: true },
        { path: 'view', element: <Files /> },
        { path: 'add', element: <AddFile /> },
        { path: 'add/:category', element: <AddFile /> },
        { path: 'add/:category/:subcategory', element: <AddFile /> },
        { path: ':asset_id', element: <ViewFile /> }
      ]
    },
    {
      path: 'profile',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/profile/view" />, index: true },
        { path: 'view', element: <Profile /> },
      ]
    },
    {
      path: 'settings',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/settings/view" />, index: true },
        { path: 'view', element: ['admin'].includes(role) ? <Settings /> : <Navigate to="/dashboard" /> },
        { path: 'add', element: ['admin'].includes(role) ? <AddEditCategorySubCategory /> : <Navigate to="/dashboard" /> },
        { path: 'edit/:categoryId', element: ['admin'].includes(role) ? <AddEditCategorySubCategory /> : <Navigate to="/dashboard" />},
      ]
    },
    { path: '404', element: <Page404 /> },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
