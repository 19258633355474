import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Swal from 'sweetalert2';

import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, TextField, InputAdornment, IconButton, Hidden, Link } from '@mui/material';

import i18n from '../../i18n';
import config from '../../config';
import { convertMessageCodeToMessage } from '../../utils/messageCodeToMessage';

import Iconify from '../../components/iconify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { resetPassword } from '../../services/auth.service';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.common.white,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.otp = props?.params?.token;
    this.state = {
      newPassword: '',
      confirmPassword: '',
      showNewPassword: false,
      showConfirmPassword: false,
      loading: false,
      error: {}
    }
  }

  resetPasskey = async () => {

    this.setState({
      loading: true
    });

    if (!(await this.isFormValid())) {
      this.setState({
        loading: false
      });
      return;
    }
    const payload = {
      otp: this.otp,
      new_password: this.state.newPassword,
      confirm_password: this.state.confirmPassword,
    }
    let signupData = await resetPassword(payload);
    if(signupData.status === config?.successCode){
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: i18n.t("success"),
        text: signupData?.data?.message || i18n.t("registration_successful_please_check_ur_email"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
      this.setState({
        loading: false
      });
      this.props.navigate("/login");
    }else{
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: signupData?.data?.error
          ? i18n.t(convertMessageCodeToMessage(signupData?.data?.error))
          : signupData?.data?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        loading: false
      })
      return;
    }

  };

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != '') {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateField(field = null) {

    let error = this.state.error;

    if (field == null || field == 'newPassword') {
      if (!this.state.newPassword || this.state.newPassword == '') {
        error.newPassword = i18n.t('this_field_is_required');
      } else {
        error.newPassword = '';
      }
    }
    if (field == null || field == 'confirmPassword') {
        if (!this.state.confirmPassword || this.state.confirmPassword == '') {
          error.confirmPassword = i18n.t('this_field_is_required');
        }else if (this.state.confirmPassword !== this.state.newPassword) {
            error.confirmPassword = i18n.t('password_dosent_match_with_the_new_password');
          } else {
          error.confirmPassword = '';
        }
      }

    this.setState({
      error: error
    })

    return error;
  }

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('login')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <StyledRoot>
          <Hidden mdDown>
            <StyledSection>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                {i18n.t('hi')}, {i18n.t('welcome_back')}
              </Typography>
              <img src="/assets/illustrations/reset-password.jpg" alt="login" />
            </StyledSection>
          </Hidden>

          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
                {i18n.t('reset_password')} {config.APPLICATION_NAME}
              </Typography>
              <Stack spacing={3}>

                <TextField
                  name="newPassword"
                  disabled={this.state.loading}
                  label={i18n.t('new_password')}
                  type={this.state.showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showNewPassword: !this.state.showNewPassword }) }} edge="end">
                          <Iconify icon={this.state.showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={async (e) => {
                    await this.setState({
                        newPassword: e.target.value
                    })
                    await this.validateField('newPassword');
                  }}
                  error={!!this.state?.error?.newPassword}
                  helperText={this.state?.error?.newPassword}
                />
                 <TextField
                  name="confirmPassword"
                  disabled={this.state.loading}
                  label={i18n.t('confirm_password')}
                  type={this.state.showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showConfirmPassword: !this.state.showConfirmPassword }) }} edge="end">
                          <Iconify icon={this.state.showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={async (e) => {
                    await this.setState({
                        confirmPassword: e.target.value
                    })
                    await this.validateField('confirmPassword');
                  }}
                  // on enter key, call login function
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.newPassword && this.state.confirmPassword) {
                      this.resetPasskey();
                    }
                  }}
                  error={!!this.state?.error?.confirmPassword}
                  helperText={this.state?.error?.confirmPassword}
                />
              </Stack>
              <Container sx={{display:'flex',justifyContent:'space-between',padding:'0 !important',mt:1}}>
              <Typography>
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <Link
                 underline="hover"
                 sx={{cursor:'pointer',
                 display:'flex',
                 alignItems:'center'
                }}
                 onClick={() => {
                 this.props.navigate("/login");
                 }}
                ><ArrowBackIcon fontSize="15px"/>{i18n.t('back_to_login')}</Link>
              </Typography>
              </Container>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={this.resetPasskey}
                sx={{ mt: 2 }}
                disabled={this.state.loading}
              >
                {this.state.loading ? i18n.t('updating_password') : i18n.t('update_password')}
              </LoadingButton>
            </StyledContent>
          </Container>
        </StyledRoot>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <ResetPassword {...props} navigate={navigate} params={params}/>;
}