import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Grid,
  styled,
  Button,
  Popover,
  Container,
  Breadcrumbs,
  Link,
  Accordion,
  AccordionSummary,
  Input,
  InputAdornment,
  Backdrop,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../../config";
import i18n from "../../i18n";
import Iconify from "../../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { getCategoriesData } from "../../services/categories.service";
import { Helmet } from "react-helmet-async";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import { debounce } from "../../utils/debounce";
import Swal from "sweetalert2";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const TABLE_HEAD = [
  {
    id: "name",
    label: i18n.t("category"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "hidden",
    label: i18n.t("hidden"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];
const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.state = {
      categories: [],
      categoryData: {},
      open: null,
      search: "",
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: {},
      orderBy: { sort_key: "name", sort_order: true },
    };
    this.role = localStorage.getItem("role");
    this.onAction = props?.onAction;
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
  }

  async componentDidMount() {
    await this.getCategories();
  }

  async getCategories() {
    this.setState({
      tableLoader: true,
    });
    let query = {
      sort_key : this.state?.orderBy?.sort_key,
      sort_order : this.state?.orderBy?.sort_order ? "ASC" : "DESC"
    };
    if (this.state.search) {
      query.search = this.state.search;
    }
    let categoryData = await getCategoriesData(query);
    if (categoryData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: categoryData?.error
          ? i18n.t(convertMessageCodeToMessage(categoryData?.error))
          : categoryData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    await this.setState({
      tableLoader: false,
      categories: categoryData?.categories,
    });
  }

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };

  handleRowClick = (categoryId) => {
    this.props.navigate(`/settings/edit/${categoryId}`);
  };

  handleTableActions = async(action,data) => {
    if (action === "sort") {
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state.orderBy?.sort_order,
        },
      });
      this.getCategories();
    }
  };

  getCategoriesDataOnChange = debounce(() => {
    this.getCategories();
  }, 500);

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("categories")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t('settings')}
              </Typography>
          </Breadcrumbs>
        </Stack>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {i18n.t("categories")}
            </Typography>
            {this.role !== "viewer" && (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  this.props.navigate(`/settings/add`);
                }}
              >
                {i18n.t("add_category")}
              </Button>
            )}
          </Stack>
        </Grid>
        <div>
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              background: "white",
            }}
            expanded={this.state.expanded}
          >
            <AccordionSummary
              style={{ background: "white" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search…"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                onChange={(e) => {
                  this.setState({
                    search: e?.target?.value,
                  });
                  this.getCategoriesDataOnChange();
                }}
                sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              />
            </AccordionSummary>
          </Accordion>
        </div>
        <br />
        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer
              style={{ width: "100%", maxHeight: "calc(100vh - 370px)" }}
              hideScrollbars={false}
            >
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["name"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.handleTableActions("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state?.categories?.map((row, index) => {
                      const { _id, name, hidden } = row;

                      return (
                        <StyledTableRow key={_id} tabIndex={-1}>
                          <StyledTableCell
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            scope="row"
                            style={{
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {hidden ? <CheckIcon /> : <CloseIcon />}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  categoryData: row,
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.files?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          No Records found
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate(
                "/settings/edit/" + this.state.categoryData._id
              );
              this.handleCloseMenu();
            }}
          >
            {this.role === "admin" ? (
              <>
                <ModeEditIcon sx={{ mr: 2 }} />
                {i18n.t("edit")}
              </>
            ) : (
              <>
                <RemoveRedEyeIcon sx={{ mr: 2 }} />
                {i18n.t("view")}
              </>
            )}
          </MenuItem>
        </Popover>

        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return <Settings {...props} navigate={navigate} params={params} />;
}
