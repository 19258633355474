import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  TextField,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  Breadcrumbs,
  Link,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../utils/debounce";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import Iconify from "../../components/iconify/Iconify";
import {
  getFilesList,
  deleteFile,
  downloadFile
} from "../../services/file.service";
import CircularProgress from "@mui/material/CircularProgress";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import { getCategoriesData } from "../../services/dashboard.service";
import ClassIcon from "@mui/icons-material/Class";
import FilesTable from "../../SharedComponent/FilesTable";

class Files extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      files: [],
      fileData: {},
      open: null,
      page: 1,
      selected: [],
      search: "",
      limit: 25,
      skip: 0,
      total_files: 0,
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: {},
      orderBy: { sort_key: "name", sort_order: true },
    };
    this.category = props?.params?.category;
    this.subCategory = props?.params?.subcategory;
    this.confirmOptions = config.confirmOptions;
    this.handleTableAction = this.handleTableAction.bind(this);
    this.role=localStorage.getItem('role');
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
  }

  async componentDidMount() {
    await this.getCategoryDetails();
  }

  async getFiles(action) {
    this.setState({
      tableLoader: true,
    });
    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key] !== "") {
        query[key] = this.state?.filters[key]?.value;
      } else {
        delete query[key];
      }
    });
    if (this.state.search) {
      query.search = this.state.search;
    }

    let files = await getFilesList(query);
    if (files.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: files?.error
          ? i18n.t(convertMessageCodeToMessage(files?.error))
          : files?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }
    await this.setState({
      tableLoader: false,
      files: files.assets,
      total_files: files.count,
    });
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  getFilesDataOnchange = debounce(() => {
    this.getFiles("filters");
  }, 500);

  async handleDeleteFile(fileId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteFile(fileId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error
          ? i18n.t(convertMessageCodeToMessage(response?.error))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    } else {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message || i18n.t("file_deleted_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });

      await this.getFiles();
      this.setState({
        pageLoader: false,
      });
    }
  }
  async handleDownloadFile(rowData) {
    this.setState({
      pageLoader: true,
    });
    let response = await downloadFile(rowData?._id);
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error
          ? i18n.t(convertMessageCodeToMessage(response?.error))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
    } else {
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", rowData?.name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }

    this.setState({
      pageLoader: false,
    });
  }
  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getFiles();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getFiles();
    } else if (action === "sort") {
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state.orderBy?.sort_order,
        },
      });
      this.getFiles();
    } else if (action === "delete") {
      this.handleDeleteFile(data);
    } else if (action === "download") {
      this.handleDownloadFile(data);
    }
  };
  async getCategoryDetails() {
    this.setState({
      pageLoader: true,
    });

    let categoryData = await getCategoriesData();
    if (categoryData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: categoryData?.error
          ? i18n.t(convertMessageCodeToMessage(categoryData?.error))
          : categoryData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    let tempCategoryData = categoryData?.categories?.filter(
      (category) => category.key === this.category
    );
    let tempSubCategoryData = tempCategoryData?.[0]?.sub_categories?.filter(
      (subCategory) => subCategory.key === this.subCategory
    );
    let tempCategoryOptions = categoryData?.categories?.map((category) => {
      return { ...category, label: category?.name, value: category?.key };
    });
    let tempSubCategoryOptions = [];
    if (tempCategoryData?.[0]?.sub_categories) {
      tempSubCategoryOptions = tempCategoryData?.[0]?.sub_categories?.map(
        (sub_category) => {
          return { label: sub_category?.name, value: sub_category?.key };
        }
      );
    }
    await this.setState(
      {
        pageLoader: false,
        categories:categoryData?.categories,
        categoryData: tempCategoryData?.[0],
        subCategoryData: tempSubCategoryData?.[0],
        categoryOptions: tempCategoryOptions,
        subCategoryOptions: tempSubCategoryOptions,
        filters: {
          category: this.category
            ? {
                label: tempCategoryData?.[0]?.name,
                value: tempCategoryData?.[0]?.key,
              }
            : "",
          sub_category: this.subCategory
            ? {
                label: tempSubCategoryData?.[0]?.name,
                value: tempSubCategoryData?.[0]?.key,
              }
            : "",
        },
        expanded: this.category ? !this.state.expanded : false,
      },
      () => {
        this.getFiles();
      }
    );
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("files")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            {this.state?.categoryData?.name &&
              this.state?.subCategoryData?.name && (
                <Link
                  underline="hover"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  color="inherit"
                  onClick={() => {
                    this.props.navigate(
                      `/dashboard/${this.state?.categoryData?.key}`
                    );
                  }}
                >
                  <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {this.state?.categoryData?.name}
                </Link>
              )}
            {this.state?.categoryData?.name &&
              !this.state?.subCategoryData?.name && (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {this.state?.categoryData?.name}
                </Typography>
              )}
            {this.state?.subCategoryData?.name && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <ClassIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {this.state?.subCategoryData?.name}
              </Typography>
            )}
            {!this.state?.categoryData?.name &&
              !this.state?.subCategoryData?.name && (
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <ClassIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {i18n.t("files")}
                </Typography>
              )}
          </Breadcrumbs>
        </Stack>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {i18n.t("files")}
            </Typography>
            {this.role!=='viewer' && <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.state?.subCategoryData?.key
                  ? this.props.navigate(
                      `/files/add/${this.state?.categoryData?.key}/${this.state?.subCategoryData?.key}`
                    )
                  : this.state?.categoryData?.key
                  ? this.props.navigate(
                      `/files/add/${this.state?.categoryData?.key}`
                    )
                  : this.props.navigate(`/files/add`);
              }}
            >
              {i18n.t("add_file")}
            </Button>}
          </Stack>
        </Grid>
        <div>
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            sx={{
              background: "white",
            }}
            expanded={this.state.expanded}
          >
            <AccordionSummary
              style={{ background: "white" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search…"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                onChange={(e) => {
                  this.setState({
                    search: e?.target?.value,
                  });
                  this.getFilesDataOnchange();
                }}
                sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              />
             {!this?.category && !this.subCategory &&  <IconButton
                onClick={() => {
                  this.handleExpandClick();
                }}
              >
                <FilterAltIcon />
              </IconButton>}
            </AccordionSummary>
            {!this?.category && !this.subCategory && <AccordionDetails>
              <Typography>
                <Grid container spacing={3} mb={2}>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={1}>
                      <Autocomplete
                        required
                        disablePortal
                        options={this.state.categoryOptions || []}
                        onChange={async (option, value) => {
                          let tempOptions = value?.sub_categories?.map(
                            (category) => {
                              return {
                                label: category?.name,
                                value: category?.key,
                              };
                            }
                          );
                          await this.setState({
                            filters: {
                              ...this.state.filters,
                              category: value ? value : "",
                            },
                            subCategoryOptions: tempOptions,
                          });
                          this.getFilesDataOnchange();
                        }}
                        value={this.state?.filters?.category?.label || ""}
                        renderInput={(params) => (
                          <TextField {...params} label={i18n.t("category")} />
                        )}
                      />
                    </Stack>
                  </Grid>
                  {this.state?.filters?.category?.label &&
                    this?.state?.subCategoryOptions?.length > 0 && (
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            options={this.state?.subCategoryOptions || []}
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state.filters,
                                  sub_category: value ? value : "",
                                },
                              });
                              this.getFilesDataOnchange();
                            }}
                            value={
                              this.state?.filters?.sub_category?.label || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("sub_category")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    )}
                </Grid>
              </Typography>
            </AccordionDetails>}
          </Accordion>
        </div>
        <br />
        <FilesTable 
        data={this.state} 
        categoryOptions={this.state.categories}
        onAction={this.handleTableAction} 
        />

        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Files {...props} navigate={navigate} params={params} />;
}
