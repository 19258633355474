import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Swal from 'sweetalert2';

import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, TextField, InputAdornment, IconButton, Hidden, Link, Input } from '@mui/material';

import i18n from '../../i18n';
import config from '../../config';
import { convertMessageCodeToMessage } from '../../utils/messageCodeToMessage';

import Logo from '../../components/logo';
import Iconify from '../../components/iconify';

import { login as authLogin } from '../../services/auth.service';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.common.white,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      loading: false,
      error: {}
    }
  }

  login = async () => {

    this.setState({
      loading: true
    });

    if (!(await this.isFormValid())) {
      this.setState({
        loading: false
      });
      return;
    }

    const payload = {
      email: this.state.email,
      password: this.state.password
    }

    let login = await authLogin(payload);
    if (login?.data?.error) {
      Swal.fire({
        icon: 'error',
        title: i18n.t('error'),
        text: login?.error_code
          ? i18n.t(convertMessageCodeToMessage(login?.data?.error))
          : login?.data?.error,
        confirmButtonText: i18n.t('ok'),
        confirmButtonColor: config.primaryColor
      });
      this.setState({
        loading: false
      });
      return;
    }
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: "success",
      title: i18n.t("success"),
      text: i18n.t("logged_in_successfully"),
      showConfirmButton: false,
      timer: config.toastDelay,
      timerProgressBar: true,
      customClass: {
        container: "my-swal",
      },
    });
    localStorage.setItem('email', login?.data.email);
    localStorage.setItem('role', login?.data.role);
    localStorage.setItem('token', login?.data.token);
    localStorage.setItem('value', login?.data.value);

    this.props.navigate('/dashboard', { replace: true });
  };

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != '') {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateField(field = null) {

    let error = this.state.error;

    if (field == null || field == 'email') {
      if (!this.state.email || this.state.email == '') {
        error.email = i18n.t('this_field_is_required');
      }
       else if(!this.state.email.toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )){
        error.email = i18n.t('enter_a_valid_email_id');
      }
      else{
        error.email = '';
      }
    }

    if (field == null || field == 'password') {
      if (!this.state.password || this.state.password == '') {
        error.password = i18n.t('this_field_is_required');
      } else {
        error.password = '';
      }
    }

    this.setState({
      error: error
    })

    return error;
  }

  render() {

    return (
      <>
        <Helmet>
          <title> {i18n.t('login')} | {config.APPLICATION_NAME} </title>
        </Helmet>

        <StyledRoot>

          <Hidden mdDown>
            <StyledSection style={{}}>
              <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                {i18n.t('hi')}, {i18n.t('welcome_back')}
              </Typography>
              <img src="/assets/illustrations/login.jpg" alt="login" />
            </StyledSection>
          </Hidden>

          <Container maxWidth="sm">
            <StyledContent>
              <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
                {i18n.t('sign_in_to')} {config.APPLICATION_NAME}
              </Typography>
              <Stack spacing={3}>
                <TextField 
                  name="email"
                  type="email"
                  disabled={this.state.loading}
                  label={i18n.t('email')}
                  onChange={async (e) => {
                    await this.setState({
                      email: e.target.value
                    })
                    await this.validateField('email');
                  }}
                  error={!!this.state?.error?.email}
                  helperText={this.state?.error?.email}
                />

                <TextField
                  name="password"
                  disabled={this.state.loading}
                  label={i18n.t('password')}
                  type={this.state.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }} edge="end">
                          <Iconify icon={this.state.showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={async (e) => {
                    await this.setState({
                      password: e.target.value
                    })
                    await this.validateField('password');
                  }}
                  // on enter key, call login function
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && this.state.email && this.state.password) {
                      this.login();
                    }
                  }}
                  error={!!this.state?.error?.password}
                  helperText={this.state?.error?.password}
                />
              </Stack>
              <Container sx={{display:'flex',justifyContent:'space-between',padding:'0 !important',mt:1}}>
              <Typography variant="body2">
              {i18n.t('new_user')}? <Link 
                underline="hover"
                sx={{cursor:'pointer'}}
                onClick={() => {
                this.props.navigate("/signup");
              }}>{i18n.t('sign_up')}</Link>
              </Typography>
              <Typography variant="body2" sx={{cursor:'pointer'}}>
                <Link underline="hover"
                onClick={() => {
                  this.props.navigate("/forgot-password");
                }}>{i18n.t('forgot_password')}?</Link>
              </Typography>
              </Container>
              
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={this.login}
                sx={{ mt: 2 }}
                disabled={this.state.loading}
              >
                {this.state.loading ? i18n.t('logging_in') : i18n.t('login')}
              </LoadingButton>
            </StyledContent>
          </Container>
        </StyledRoot>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();

  return <Login {...props} navigate={navigate} />;
}