import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import BadgeIcon from "@mui/icons-material/Badge";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import { addUser, editUser, getUser } from "../../services/user.service";
import config from "../../config";

class AddEditUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props?.params?.user_id ? "edit" : "add",
      user_id: props.params?.user_id,
      user: {},
      showPassword: false,
      loading: false,
      tableLoader: false,
      errors: {},
      role: [],
    };
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getUserData();
    }
    let tempRoleOptions = config?.roleOptions?.filter((role) => role?.internal);
    this.setState({
      role: tempRoleOptions,
    });
  }

  async getUserData() {
    this.setState({
      loading: true,
      tableLoader: true,
    });
    let response = await getUser(this.state.user_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error
          ? i18n.t(convertMessageCodeToMessage(response?.error))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.user) {
      let userData = response.user;
      let tempRole = config?.roles?.filter(
        (role) => role?.value === userData?.role
      )?.[0];
      await this.setState({
        user: {
          ...userData,
          role: tempRole,
        },
        loading: false,
        tableLoader: false,
      });
    }
  }

  async saveUser() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });
    let payload = {
      ...this.state.user,
      role: this.state.user?.role?.value,
    };

    if (this.state.mode === "add") {
      let response = await addUser(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error
            ? i18n.t(convertMessageCodeToMessage(response?.error))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.user) {
        this.setState({
          loading: false,
        });
        this.props.navigate("/users/view");
      }
    } else {
      let response = await editUser(this.state.user_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error
            ? i18n.t(convertMessageCodeToMessage(response?.error))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.user) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.message
          ? i18n.t(convertMessageCodeToMessage(response?.message))
          : i18n.t("user_details_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }

  goBack() {
    this.props.navigate(-1);
  }

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.errors };

    if (field == null || field == "email") {
      if (!this.state?.user?.email || this.state.user?.email == "") {
        error["email"] = i18n.t("this_field_is_required");
      } else {
        delete error["email"];
      }
    }

    if (field == null || field == "role") {
      if (!this.state?.user?.role || this.state.user?.role == "") {
        error["role"] = i18n.t("this_field_is_required");
      } else {
        delete error["role"];
      }
    }
    
    if (field == null || field == "status") {
      if (!this.state?.user?.status || this.state.user?.status == "") {
        error["status"] = i18n.t("this_field_is_required");
      } else {
        delete error["status"];
      }
    }
    this.setState({
      errors: error,
    });

    return error;
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("user")} | BTEE </title>
        </Helmet>

        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/users/view");
              }}
            >
              <PeopleOutlineIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("users")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {this.state.mode === "add" ? (
                <>
                  <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {i18n.t("add")}
                </>
              ) : (
                <>
                  <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {this.state?.user?.email}
                </>
              )}
            </Typography>
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("email")}
                  variant="outlined"
                  value={this.state?.user?.email || ""}
                  onChange={async (e) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        email: e.target.value,
                      },
                    });
                    await this.validateField("email");
                  }}
                  error={this.state?.errors?.email ? true : false}
                  helperText={this.state?.errors?.email}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  required
                  disablePortal
                  options={config?.roles}
                  onChange={async (option, value) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        role: value,
                      },
                    });
                    await this.validateField("role");
                  }}
                  value={this.state?.user?.role?.label || ""}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("role")}
                      error={this.state?.errors?.role ? true : false}
                      helperText={this.state?.errors?.role}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  required
                  disablePortal
                  options={config?.status}
                  onChange={async (option, value) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        status: value?.value,
                      },
                    });
                    await this.validateField("status");
                  }}
                  value={i18n.t(this.state?.user?.status) || ""}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("status")}
                      error={this.state?.errors?.status ? true : false}
                      helperText={this.state?.errors?.status}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveUser();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_user")
                  : i18n.t("update_user")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditUser {...props} navigate={navigate} params={params} />;
}
