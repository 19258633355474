import React from "react";
import { Helmet } from "react-helmet-async";
import {
  Accordion,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import { getCategoriesData } from "../services/dashboard.service";
import i18n from "src/i18n";
import config from "src/config";
import { useNavigate, useParams } from "react-router-dom";
import SharedCard from "../SharedComponent/SharedCard";
import Swal from "sweetalert2";
import FilesTable from "../SharedComponent/FilesTable";
import { debounce } from "../utils/debounce";
import Iconify from "../components/iconify";
import {
  deleteFile,
  downloadFile,
  getFilesList,
} from "../services/file.service";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      categories: [],
      search: "",
      filesData: {
        files: [],
        fileData: {},
        open: null,
        page: 1,
        limit: 25,
        skip: 0,
        total_files: 0,
        tableLoader: false,
        orderBy: { sort_key: "name", sort_order: true },
      },
    };
  }
  async componentDidMount() {
    await this.getCategoryDetails();
  }

  async getCategoryDetails() {
    this.setState({
      pageLoader: true,
    });
    let categoryData = await getCategoriesData();
    if (categoryData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: categoryData?.error
          ? i18n.t(convertMessageCodeToMessage(categoryData?.error))
          : categoryData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    this.setState({
      pageLoader: false,
      categories: categoryData.categories,
    });
  }
  async getFiles(action) {
    await this.setState({
      filesData: {
        ...this.state.filesData,
        tableLoader: true,
      },
    });
    let query = {
      limit: this.state?.filesData?.limit,
      skip: this.state?.filesData?.skip,
      sort_key: this.state?.filesData?.orderBy?.sort_key,
      sort_order: this.state?.filesData?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        filesData: {
          ...this.state?.filesData,
          skip: 0,
          page: 1,
        },
      });
    }
    if (this.state.search) {
      query.search = this.state.search;
    }

    let files = await getFilesList(query);
    if (files.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: files?.error
          ? i18n.t(convertMessageCodeToMessage(files?.error))
          : files?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        filesData: {
          ...this.state?.filesData,
          tableLoader: false,
        },
      });
      return;
    }
    await this.setState({
      filesData: {
        ...this.state?.filesData,
        tableLoader: false,
        files: files.assets,
        total_files: files.count,
      },
    });
  }
  handleTableAction = async (action, data) => {
    if (action === "pagination") {
      await this.setState({
        filesData: {
          ...this.state?.filesData,
          skip: (data - 1) * this.state?.filesData.limit,
          page: data,
        },
      });
      this.getFiles();
    } else if (action === "limit") {
      await this.setState({
        filesData: {
          ...this.state?.filesData,
          limit: data,
        },
      });
      this.getFiles();
    } else if (action === "sort") {
      await this.setState({
        filesData: {
          ...this.state?.filesData,
          orderBy: {
            sort_key: data,
            sort_order: !this.state?.filesData?.orderBy?.sort_order,
          },
        },
      });
      this.getFiles();
    } else if (action === "delete") {
      this.handleDeleteFile(data);
    } else if (action === "download") {
      this.handleDownloadFile(data);
    }
  };

  async handleDeleteFile(fileId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteFile(fileId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error
          ? i18n.t(convertMessageCodeToMessage(response?.error))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    } else {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message || i18n.t("file_deleted_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });

      await this.getFiles();
      this.setState({
        pageLoader: false,
      });
    }
  }
  async handleDownloadFile(rowData) {
    this.setState({
      pageLoader: true,
    });
    let response = await downloadFile(rowData?._id);
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error
          ? i18n.t(convertMessageCodeToMessage(response?.error))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
    } else {
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", rowData?.name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }

    this.setState({
      pageLoader: false,
    });
  }
  getFilesDataOnchange = debounce(async () => {
    this.getFiles("filters");
  }, 500);
  render() {
    return (
      <>
        <Helmet>
          <title>
            {" "}
            {i18n.t("dashboard")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Container maxWidth="xxl">
          <Typography variant="h4" sx={{ mb: 5 }}>
            {i18n.t("hi")}, {i18n.t("welcome_back")}
          </Typography>
          <div style={{marginBottom:'20px'}}>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              sx={{
                background: "white",
                mb: 2,
              }}
              expanded={this.state.expanded}
            >
              <AccordionSummary
                style={{ background: "white" }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Input
                  autoFocus
                  fullWidth
                  disableUnderline
                  placeholder="Search…"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled", width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  }
                  onChange={async (e) => {
                    await this.setState({
                      search: e?.target?.value,
                      filesData: {
                        ...this.state?.filesData,
                        tableLoader: true,
                      },
                    });
                    this.getFilesDataOnchange();
                  }}
                  sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                />
              </AccordionSummary>
            </Accordion>
          </div>
          {this?.state?.search?.length === 0 && (
            <Grid
              container
              spacing={3}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SharedCard categories={this.state.categories} />
            </Grid>
          )}
          {this?.state?.search?.length > 0 && (
            <FilesTable
              data={this.state?.filesData}
              categoryOptions={this.state.categories}
              onAction={this.handleTableAction}
            />
          )}
        </Container>
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Dashboard {...props} navigate={navigate} params={params} />;
}
