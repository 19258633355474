const { default: i18n } = require("./i18n");

module.exports = {
    SERVER_BASE_URL: process.env.REACT_APP_BASE_URL,
    APPLICATION_NAME: 'BPFM',
    version: "1.1.17",
    primaryColor: '#2065d1',
    primaryColorHighlighted: '#103996',
    toastDelay: 3000,
    successCode:200,
    errorCodes:[400,500,404],
    DATE_TIME_FORMAT: {
        TIME_FORMAT_12H: 'LT'
    },
    tableLimitOptions:[
        {label:"10",value:10},
        {label:"25",value:25},
        {label:"50",value:50},
        {label:"100",value:100}
    ],
    roles:[
        {label:i18n.t('Admin'),value:'admin'},
        {label:i18n.t('document_updater'),value:'document_updater'},
        {label:i18n.t('Viewer'),value:'viewer'}
    ],
    status:[
        {label: i18n.t('active'),value:'active', color:"#04aa6d"},
        {label: i18n.t('Inactive'),value:'inactive',color:"#BA0F30"},
    ],
    confirmOptions:[
        { label: i18n.t('yes'), value: 'yes' , color:"#04aa6d" },
        { label: i18n.t('no'), value: 'no' , color:"#BA0F30"}
    ]


}