// component
import i18n from 'src/i18n';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('dashboard'),
    path: '/dashboard',
    icon: <BarChartRoundedIcon />,
  },
  {
    title: i18n.t('files'),
    path: '/files',
    icon: <DriveFileMoveIcon />,
  },
  {
    title: i18n.t('users'),
    path: '/users',
    access:['admin'],
    icon: <PeopleOutlineIcon />,
  },
  {
    title: i18n.t('settings'),
    path: '/settings',
    access:['admin'],
    icon: <SettingsIcon />,
  }
];

export default navConfig;
