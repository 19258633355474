import React from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
// mocks_
import account from '../../../_mock/account';
import { useNavigate } from 'react-router-dom';
import i18n from 'src/i18n';

// ----------------------------------------------------------------------

class AccountPopover extends React.Component {
  constructor(props) {
    super(props);
    this.username = localStorage.getItem('username');
    this.role = localStorage.getItem('role');
    this.props = props;
    this.state = {
      open:false,
      currentTarget:''
    }
  }


  handlePopUp = async(event) => {
    this.setState({
      open: !this.state.open,
      currentTarget:event?.currentTarget
    });
  };




  render() {
    return (
      <>
        <IconButton
          onClick={(e)=>this.handlePopUp(e)}
          sx={{
            p: 0,
            ...(this.state.open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Avatar src={account.photoURL} alt="photoURL" />
        </IconButton>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.currentTarget}
          onClose={()=>this.handlePopUp()}
          getOffsetTop="bottom"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            top:60,
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle2" noWrap>
              {this.username}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {i18n.t(this.role)}
            </Typography>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack sx={{ p: 1 }}>
            <MenuItem
              onClick={() => {
                this.handlePopUp();
                this.props.navigate('/profile/view');
              }}
            >
              {i18n.t('profile')}
            </MenuItem>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <MenuItem onClick={()=>{localStorage.clear();this.props.navigate('/login', { replace: true })}} sx={{ m: 1 }}>
            {i18n.t('logout')}
          </MenuItem>
        </Popover>
      </>
    );
  }
}
// Wrap and export
export default function (props) {
  const navigate = useNavigate();

  return <AccountPopover {...props} navigate={navigate} />;
}