import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Link,
  Breadcrumbs,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import config from "../config";
import i18n from "../i18n";
import { convertMessageCodeToMessage } from "../utils/messageCodeToMessage";
import HomeIcon from "@mui/icons-material/Home";
import BadgeIcon from "@mui/icons-material/Badge";
import Iconify from "../components/iconify/Iconify";
import { editProfileDetails, getProfile } from "../services/profile.service";
import { LoadingButton } from "@mui/lab";


class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        profileData:{},
        formErrors:{},
        pageLoader:false,
        showPassword:{showOldPassword:false,showNewPassword:false,showConfirmNewPassword:false}
    };
  }

  async componentDidMount() {
    this.getProfileData();
  }

  async getProfileData() {
    this.setState({
      pageLoader: true,
    });

    let profileData = await getProfile();
    if (profileData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: profileData?.error
          ? i18n.t(convertMessageCodeToMessage(profileData?.error))
          : profileData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false
      })
      return;
    }

    this.setState({
      pageLoader: false,
      profileData: profileData.user
    });
  }
  async updateProfileData() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      pageLoader: true,
    });
    let payload={
      old_password:this.state.profileData?.old_password,
      new_password:this.state.profileData?.new_password,
      confirm_password:this.state.profileData?.confirm_password,
    }

    let profileData = await editProfileDetails(payload);
    if (profileData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: profileData?.error
          ? i18n.t(convertMessageCodeToMessage(profileData?.error))
          : profileData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false
      })
      return;
    }

     // Swal toast
     Swal.fire({
      toast: true,
      position: "top-end",
      icon: "success",
      title: i18n.t("success"),
      text: i18n.t(profileData?.message),
      showConfirmButton: false,
      timer: config.toastDelay,
      timerProgressBar: true,
    });
    this.setState({
      pageLoader: false
    });
  }

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != '') {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateField(field = null) {

    let error = {...this.state?.formErrors?.errors};

    if (field == null || field == 'new_password') {
      if (!this.state?.profileData?.new_password || this.state.profileData?.new_password == '') {
        error['new_password'] = i18n.t('this_field_is_required');
      } else {
        delete error['new_password'];
      }
    }
    if (field == null || field == 'confirm_password') {
      
      if (!this.state?.profileData?.confirm_password || this.state.profileData?.confirm_password == '') {
        error['confirm_password'] = i18n.t('this_field_is_required');
      } else if(this.state?.profileData?.confirm_password  !== this.state?.profileData?.new_password ){
        error['confirm_password'] = i18n.t('password_dosent_match_the_new_password');
      }else {
        delete error['confirm_password'];
      }
    }

    this.setState({
      formErrors:{...this.state.formErrors,errors:error}
    })

    return error;
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("profile")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>

        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h4" gutterBottom>
              {i18n.t("profile")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("profile")}
            </Typography>
          </Breadcrumbs>
        </Stack>
        </Grid>
        <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("email")}
                  variant="outlined"
                  value={this.state?.profileData?.email || ""}
                  disabled
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("role")}
                  variant="outlined"
                  value={this.state?.profileData?.role || ""}
                  disabled
                />
              </Stack>
            </Grid>
            </Grid>
            <Typography variant="h5" gutterBottom>
              {i18n.t("change_password")}
            </Typography>
            <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("old_password")}
                  type={this.state.showPassword?.showOldPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showPassword: {...this.state.showPassword,showOldPassword:!this.state.showPassword?.showOldPassword} }) }} edge="end">
                          <Iconify icon={this.state.showPassword?.showOldPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={this.state?.profileData?.old_password || ""}
                  onChange={async (e) => {
                    await this.setState({
                      profileData: {
                        ...this.state.profileData,
                        old_password: e.target.value,
                      },
                    });
                    await this.validateField('old_password')
                  }}
                  error={
                    this.state.formErrors?.errors?.old_password
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.old_password}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("new_password")}
                  type={this.state.showPassword?.showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showPassword: {...this.state.showPassword,showNewPassword:!this.state.showPassword?.showNewPassword} }) }} edge="end">
                          <Iconify icon={this.state.showPassword?.showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={this.state?.profileData?.new_password || ""}
                  onChange={async (e) => {
                    await this.setState({
                      profileData: {
                        ...this.state.profileData,
                        new_password: e.target.value,
                      },
                    });
                    await this.validateField('new_password')
                  }}
                  error={
                    this.state.formErrors?.errors?.new_password
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.new_password}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("confirm_new_password")}
                  type={this.state.showPassword?.showConfirmNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showPassword: {...this.state.showPassword,showConfirmNewPassword:!this.state.showPassword?.showConfirmNewPassword} }) }} edge="end">
                          <Iconify icon={this.state.showPassword?.showConfirmNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  value={this.state?.profileData?.confirm_password || ""}
                  onChange={async (e) => {
                    await this.setState({
                      profileData: {
                        ...this.state.profileData,
                        confirm_password: e.target.value,
                      },
                    });
                    await this.validateField('confirm_password')
                  }}
                  error={
                    this.state.formErrors?.errors?.confirm_password
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.confirm_password}
                />
              </Stack>
            </Grid>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" ,mt:2}}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.updateProfileData();
                }}
              >
                {this.state.pageLoader
                  ? i18n.t("updating_password")
                  : i18n.t("update_password")}
              </Button>
            </LoadingButton>
          </Grid>
          

        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Profile {...props} navigate={navigate} params={params} />;
}
