import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useParams } from "react-router-dom";

class SharedCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: props?.categories,
      categoryData: props?.categoryData,
    };
    this.onAction = props.onAction;
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps?.categories) {
      this.setState({
        data: nextProps?.categories,
        categoryData: nextProps?.categoryData,
      });
    }
  }
  render() {
    return this.state.data?.map((element) => {
      return (
       !element.hidden && 
        <Grid item xs={12} sm={6} md={3} xl={2} key={element?._id}>
          <Card
            sx={{ cursor: "pointer" }}
            onClick={() => {
              element?.sub_categories?.length > 0
                ? this.props.navigate(`/dashboard/${element?.key}`)
                : this.state.categoryData?.key
                ? this.props.navigate(
                    `/dashboard/${this.state.categoryData?.key}/${element?.key}/files`
                  )
                : this.props.navigate(`/dashboard/${element?.key}/files`);
            }}
          >
            <CardContent sx={{ padding: "0!important" }}>
              <img
                src={`/assets/images/products/${element?.key}.png`}
                style={{ width: "100%", height: "20vh" }}
                onError={(e) =>
                  (e.target.src = `/assets/images/products/defaultCategory.png`)
                }
              />
              <Grid
                container
                sx={{ display: "flex" }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={11}>
                  <Typography
                    gutterBottom
                    sx={{ paddingLeft: "10px" }}
                    variant="h7"
                    component="div"
                  >
                    {element?.name}
                  </Typography>
                </Grid>
                <Grid item xs={1}>
                  <ArrowForwardIcon
                    sx={{
                      fontSize: "1rem",
                      marginRight: "10px",
                    }}
                    color="primary"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      );
    });
  }
}
// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <SharedCard {...props} navigate={navigate} params={params} />;
}
