import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Popover,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { formatBytes } from "../services/common.service";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const TABLE_HEAD = [
  {
    id: "name",
    label: i18n.t("name"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "category",
    label: i18n.t("category"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "sub_category",
    label: i18n.t("sub_category"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "size",
    label: i18n.t("size"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "uploaded_by",
    label: i18n.t("uploaded_by"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "created_at",
    label: i18n.t("created_at"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "type",
    label: i18n.t("type"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];
const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class FilesTable extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.state = {
      ...props.data
    };
    this.role = localStorage.getItem('role');
    this.onAction=props?.onAction;
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state,...nextProps?.data,categoryOptions:nextProps?.categoryOptions });
  }
  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };
  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  handleRowClick = (fileId) => {
    this.props.navigate(`/files/${fileId}`);
  };

  render() {
    return (
      <>
        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer
              style={{ width: "100%", maxHeight: "calc(100vh - 370px)" }}
              hideScrollbars={false}
            >
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["name"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.onAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state?.files?.map((row, index) => {
                      const { _id, name, category, sub_category ,size ,type ,uploaded_by ,created_at } = row;

                      return (
                        <StyledTableRow key={_id} tabIndex={-1}>
                          <StyledTableCell
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="left"
                           style={{
                            cursor: "pointer",
                          }}
                          onDoubleClick={() => {
                            this.handleRowClick(_id);
                          }}>
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                style={{ margin: "auto", display: "block" }}
                              >
                                <Typography variant="subtitle2">
                                    {this.state?.categoryOptions?.filter(categoryData=>categoryData?.key === category)?.[0]?.name || '-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left"
                           style={{
                            cursor: "pointer",
                          }}
                          onDoubleClick={() => {
                            this.handleRowClick(_id);
                          }}>
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                style={{ margin: "auto", display: "block" }}
                              >
                                <Typography variant="subtitle2">
                                    {this.state?.categoryOptions?.filter(categoryData=>categoryData?.key === category)?.[0]?.sub_categories?.filter(subCategory=>subCategory?.key === sub_category)?.[0]?.name || '-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            scope="row"
                            style={{
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {formatBytes(size)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left"
                           style={{
                            cursor: "pointer",
                          }}
                          onDoubleClick={() => {
                            this.handleRowClick(_id);
                          }}>
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                style={{ margin: "auto", display: "block" }}
                              >
                                <Typography variant="subtitle2">
                                    {uploaded_by}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left"
                           style={{
                            cursor: "pointer",
                          }}
                          onDoubleClick={() => {
                            this.handleRowClick(_id);
                          }}>
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                style={{ margin: "auto", display: "block" }}
                              >
                                <Typography variant="subtitle2">
                                    {moment(created_at).format("DD-MM-YYYY hh:mm A")}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            scope="row"
                            style={{
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {type}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(e) => {
                                this.setState({
                                  file: row,
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.files?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          No Records found
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        <Grid
          container
          spacing={2}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid></Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(this.state.total_files / this.state.limit)}
              shape="rounded"
              size="large"
              onChange={async (event, page) => {
                this.onAction("pagination", page);
              }}
              page={this.state.page}
              showFirstButton={true}
              showLastButton={true}
              boundaryCount={2}
              style={{ margin: 20 }}
            />
          </Grid>
          <Grid item style={{ width: "10%" }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Limit</InputLabel>
              <Select
                value={this.state.limit}
                label="Limit"
                size="small"
                onChange={async (e) => {
                  this.onAction("limit", e?.target?.value);
                }}
              >
                {this.tableLimitOptions?.map((limit) => {
                  return (
                    <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate("/files/" + this.state.file._id);
              this.handleCloseMenu();
            }}
          >
            {this.role === 'admin' ?
            <>
            <ModeEditIcon sx={{mr:2}}/>
            {i18n.t('edit') }
            </>
            :
            <>
            <RemoveRedEyeIcon sx={{mr:2}}/>
            {i18n.t("view")}
            </>
            
          }
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.onAction('download',this.state.file);
              this.handleCloseMenu();
            }}
          >
            <DownloadIcon sx={{ mr: 2 }} />
            {i18n.t("download")}
          </MenuItem>
          {this.role === 'admin' && <MenuItem
            onClick={() => {
              this.setState({
                deleteFilePopUp: true,
              });
              this.handleCloseMenu();
            }}
          >
            <DeleteIcon sx={{ mr: 2 }} />
            {i18n.t("delete")}
          </MenuItem>}
        </Popover>
        <Dialog
          open={this.state?.deleteFilePopUp}
          onClose={() => {
            this.setState({
              deleteFilePopUp: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure of deleting , this action is irreversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await this.setState({
                  deleteFilePopUp: false,
                });
                this.onAction('delete',this.state.file._id);
              }}
            >
              Proceed
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  deleteUserPopUp: false,
                });
              }}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return <FilesTable {...props} navigate={navigate} params={params} />;
}
