import axios from 'axios'
import config from './../config';

const register = async (payload) => {
    try {
        const { email, password } = payload;
        let response;
        console.log(config.SERVER_BASE_URL,'config.SERVER_BASE_URL')
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/register', { email, password });
        } catch (error) {
            console.log('Catch Response error', error);
            response =  error.response;
        }
        return response;
    } catch (error) {
        return { error: error.message };
    }
}

const login = async (payload) => {
    try {
        const { email, password } = payload;
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/login', { email, password });
        } catch (error) {
            console.log('Catch Response error', error);
            response = error.response;
        }
        return response;
    } catch (error) {
        return { error: error.message };
    }
}

const forgotPassword = async (payload) => {
    try {
        const { email } = payload;
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/forgot_password', { email });
        } catch (error) {
            console.log('Catch Response error', error);
            response = error.response;
        }
        return response;
    } catch (error) {
        return { error: error.message };
    }
}

const verifyEmail = async (payload) => {
    try {
        const { otp } = payload;
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/verify_email', { otp });
        } catch (error) {
            console.log('Catch Response error', error);
            response = error.response;
        }
        return response;
    } catch (error) {
        return { error: error.message };
    }
}

const resetPassword = async (payload) => {
    try {
        const { otp,new_password,confirm_password } = payload;
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/reset_password', { otp ,new_password,confirm_password});
        } catch (error) {
            console.log('Catch Response error', error);
            response = error.response;
        }
        return response;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    register,
    forgotPassword,
    verifyEmail,
    login,
    resetPassword
}