import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Grid,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Popover,
  Container,
  Breadcrumbs,
  Link,
  Backdrop,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../../config";
import i18n from "../../i18n";
import Iconify from "../../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  getCategoryData,
  addCategoryData,
  updateCategoryData,
} from "../../services/categories.service";
import { Helmet } from "react-helmet-async";
import HomeIcon from "@mui/icons-material/Home";
import CategoryIcon from "@mui/icons-material/Category";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const DialogContainer = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const TABLE_HEAD = [
  {
    id: "name",
    label: i18n.t("sub_category"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "hidden",
    label: i18n.t("hidden"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];
const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class AddEditCategorySubCategory extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      mode: props?.params?.categoryId ? "edit" : "add",
      categoryData: {},
      subCategoryData: {},
      open: null,
      search: "",
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: {},
      orderBy: { sort_key: "name", sort_order: true },
      errors: {categoryErrors:{},subCategoryErrors:{}},
    };
    this.role = localStorage.getItem("role");
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    this.categoryId = props.params.categoryId;
  }
  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getCategoryDetails();
    }
  }

  async getCategoryDetails() {
    this.setState({
      tableLoader: true,
      pageLoader: true,
    });

    let query = {
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    let categoryData = await getCategoryData(this.categoryId, query);
    if (categoryData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: categoryData?.error
          ? i18n.t(convertMessageCodeToMessage(categoryData?.error))
          : categoryData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    await this.setState({
      pageLoader: false,
      tableLoader: false,
      categoryData: categoryData?.category,
    });
  }
  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };
  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  handleRowClick = (categoryId) => {
    this.props.navigate(`/settings/${categoryId}`);
  };

  handleTableActions = async (action, data) => {
    if (action === "sort") {
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state.orderBy?.sort_order,
        },
      });
      this.getCategoryDetails();
    }
  };
   handleAddEditSubCategory = async(mode) => {
    if (!(await this.isSubCategoryFormValid())) {
      return;
    }

    if (this.state.categoryData?.sub_categories?.length > 0) {
      let validated = false;
      this.state.categoryData?.sub_categories?.map((subCategory) => {
        if (
          subCategory?.name?.trim()?.toLowerCase() ===
            this.state?.subCategoryData?.name?.trim()?.toLowerCase() &&
            subCategory?._id !==this.state?.subCategoryData?._id &&
            !validated
        ) {
          validated = true;
        }
      });
      let error = { ...this.state.errors?.subCategoryErrors };
      if (validated) {
        error["subCategoryName"] = i18n.t(
          "sub_category_with_this_name_already_exists"
        );
        this.setState({
          errors:{
            ...this.state?.errors,
            subCategoryErrors:error
          }
        });
        return;
      } else {
        delete error["subCategoryName"];
        this.setState({
          errors:{
            ...this.state?.errors,
            subCategoryErrors:error
          }
        });
      }
    }

    if (mode === "add") {
      let tempSubCategoryData = [];
      if (this.state.categoryData?.sub_categories) {
        tempSubCategoryData = [
          ...this.state.categoryData?.sub_categories,
          {...this.state?.subCategoryData,name:this.state?.subCategoryData?.name?.trim()},
        ];
      } else {
        tempSubCategoryData = [this.state?.subCategoryData];
      }
      this.setState({
        categoryData: {
          ...this.state.categoryData,
          sub_categories: tempSubCategoryData,
        },
        showAddEditSubCategoryModal: false,
      });
    } else if (mode === "edit") {
      let tempData = { ...this.state.categoryData};
      tempData["sub_categories"][this.state.subCategoryDataIndex] ={...this.state?.subCategoryData,name:this.state?.subCategoryData?.name?.trim()},
      this.setState({
        categoryData: tempData,
        showAddEditSubCategoryModal: false,
      });
    } else if (mode === "delete") {
      let tempData = { ...this.state.categoryData };
      delete tempData["sub_categories"][this.state.subCategoryDataIndex];
      tempData["sub_categories"] = tempData?.["sub_categories"].filter(
        function (element) {
          return element !== undefined;
        }
      );
      this.setState({
        categoryData: tempData,
        showAddEditSubCategoryModal: false,
        deleteSubCategory: false,
      });
    }
  };

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async saveCategoryData() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      pageLoader: true,
    });

    let payload = {
      ...this.state.categoryData,
    };

    if (this.state.mode === "add") {
      let response = await addCategoryData(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error
            ? i18n.t(convertMessageCodeToMessage(response?.error))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          pageLoader: false,
        });
        return;
      }
      if (response && response.category) {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("category_added_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          pageLoader: false,
        });
        this.goBack();
      }
    } else {
      let response = await updateCategoryData(
        this.state.categoryData?._id,
        payload
      );
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error
            ? i18n.t(convertMessageCodeToMessage(response?.error))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          pageLoader: false,
        });
        return;
      }

      if (response && response.category) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("category_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          pageLoader: false,
        });
        this.goBack();
      }
    }
  }

  goBack() {
    this.props.navigate(-1);
  }

  async validateField(field = null) {
    let error = { ...this.state?.errors?.categoryErrors };

    if (field == null || field == "name") {
      if (
        !this.state?.categoryData?.name ||
        this.state.categoryData?.name == ""
      ) {
        error["categoryName"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["categoryName"];
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        categoryErrors:error
      },
    });

    return error;
  }

  async isSubCategoryFormValid() {
    let error = await this.validateSubCategoryField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateSubCategoryField(field = null) {
    let error = { ...this.state?.errors?.subCategoryErrors };

    if (field == null || field == "name") {
      if (
        !this.state?.subCategoryData?.name ||
        this.state.subCategoryData?.name == ""
      ) {
        error["subCategoryName"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["subCategoryName"];
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        subCategoryErrors:error
      },
    });

    return error;
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("category")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              Home
            </Link>
            {this.state?.categoryData?.name && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <CategoryIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {this.state?.categoryData?.name}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {i18n.t("category")}
            </Typography>
            {this.role !== "viewer" && (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => {
                  this.setState({
                    showAddEditSubCategoryModal: true,
                    subCategoryData: {},
                    errors:{
                      ...this.state?.errors,
                      subCategoryErrors:{}
                    }
                  });
                }}
              >
                {i18n.t("add_sub_category")}
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <TextField
              required
              id="outlined-basic"
              label={i18n.t("name")}
              variant="outlined"
              value={this.state?.categoryData?.name || ""}
              onChange={async (e) => {
                await this.setState({
                  categoryData: {
                    ...this.state.categoryData,
                    name: e?.target?.value?.trim(),
                  },
                });
                await this.validateField("name");
              }}
              error={this.state?.errors?.categoryErrors?.categoryName ? true : false}
              helperText={this.state?.errors?.categoryErrors?.categoryName}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <FormControlLabel
              label={i18n.t("hidden")}
              control={
                <Switch
                  checked={this.state?.categoryData?.hidden}
                  onChange={async (e) => {
                    await this.setState({
                      categoryData: {
                        ...this.state.categoryData,
                        hidden: e?.target?.checked,
                      },
                    });
                  }}
                />
              }
            />
          </Stack>
        </Grid>
        {this.state?.categoryData?.sub_categories?.length > 0 && (
          <Card
            sx={{ mt: 4 }}
            style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <ScrollContainer
                style={{ width: "100%", maxHeight: "calc(100vh - 455px)" }}
                hideScrollbars={false}
              >
                <Table stickyHeader size="small" aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell) => (
                        <StyledTableCell
                          style={
                            ["name"].includes(headCell.id)
                              ? this.useStyles?.sticky
                              : {}
                          }
                          key={headCell.id}
                          align={
                            headCell.alignCenter
                              ? "center"
                              : headCell.alignRight
                              ? "right"
                              : "left"
                          }
                        >
                          {headCell.sortable ? (
                            <TableSortLabel
                              direction={
                                this.state.orderBy?.sort_order ? "asc" : "desc"
                              }
                              onClick={async (e) => {
                                this.handleTableActions("sort", headCell.id);
                              }}
                              style={{ color: "white" }}
                              hideSortIcon={headCell.sortable ? false : true}
                              active={
                                this.state?.orderBy?.sort_key === headCell.id
                              }
                            >
                              {headCell.label}
                              {this.state?.orderBy?.sort_key === headCell.id &&
                              headCell.sortable ? (
                                <Box sx={{ ...visuallyHidden }}>
                                  {this.state?.orderBy?.sort_order === "desc"
                                    ? "sorted descending"
                                    : "sorted ascending"}
                                </Box>
                              ) : null}
                            </TableSortLabel>
                          ) : (
                            <Typography variant="subtitle2">
                              {headCell.label}
                            </Typography>
                          )}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!this.state?.tableLoader &&
                      this.state?.categoryData?.sub_categories?.map(
                        (row, index) => {
                          const { _id, name, hidden } = row;

                          return (
                            <StyledTableRow key={_id} tabIndex={-1}>
                              <StyledTableCell
                                scope="row"
                                style={{
                                  position: "sticky",
                                  left: 0,
                                  backgroundColor:
                                    index % 2 ? "white" : "#F6F7F8",
                                  minWidth: "10rem",
                                  cursor: "pointer",
                                }}
                                onDoubleClick={() => {
                                  this.handleRowClick(_id);
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={6}
                                >
                                  <Stack
                                    direction="column"
                                    spacing={0}
                                    sx={{ typography: "body2" }}
                                  >
                                    <Typography variant="subtitle2">
                                      {name}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </StyledTableCell>

                              <StyledTableCell
                                scope="row"
                                style={{
                                  cursor: "pointer",
                                }}
                                onDoubleClick={() => {
                                  this.handleRowClick(_id);
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={6}
                                >
                                  <Stack
                                    direction="column"
                                    spacing={0}
                                    sx={{ typography: "body2" }}
                                  >
                                    <Typography variant="subtitle2">
                                      {hidden ? <CheckIcon /> : <CloseIcon />}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </StyledTableCell>

                              <StyledTableCell align="right">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(e) => {
                                    this.setState({
                                      subCategoryData: row,
                                      subCategoryDataIndex: index,
                                    });
                                    this.handleOpenMenu(e);
                                  }}
                                >
                                  <Iconify icon={"eva:more-vertical-fill"} />
                                </IconButton>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                      )}
                    {this.state?.tableLoader && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}

                    {!this.state?.tableLoader &&
                      this.state.files?.length === 0 && (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={"100%"}
                          >
                            No Records found
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </ScrollContainer>
            </Paper>
          </Card>
        )}
        <Grid item xs={4} style={{ textAlign: "center" , marginTop:'10px'}}>
          <LoadingButton>
            <Button
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={this.state.loading}
              onClick={async () => {
                await this.saveCategoryData();
              }}
            >
              {this.state.loading
                ? i18n.t("saving")
                : this.state.mode === "add"
                ? i18n.t("add_category")
                : i18n.t("update_category")}
            </Button>
          </LoadingButton>
        </Grid>

        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.handleCloseMenu();
              this.setState({
                editSubCategory: true,
                showAddEditSubCategoryModal: true,
                errors:{
                  ...this.state.errors,
                  subCategoryErrors:{}
                }
              });
            }}
          >
            <>
              <ModeEditIcon sx={{ mr: 2 }} />
              {i18n.t("edit")}
            </>
          </MenuItem>
          {!this.state?.subCategoryData?._id && (
            <MenuItem
              onClick={() => {
                this.handleCloseMenu();
                this.setState({
                  deleteSubCategory: true,
                });
              }}
            >
              <>
                <DeleteIcon sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </>
            </MenuItem>
          )}
        </Popover>

        <React.Fragment>
          <DialogContainer
            onClose={() => [
              this.setState({
                showAddEditSubCategoryModal: false,
              }),
            ]}
            fullWidth={"40%"}
            aria-labelledby="customized-dialog-title"
            open={this.state?.showAddEditSubCategoryModal}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {i18n.t("add_sub_category")}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => {
                this.setState({
                  showAddEditSubCategoryModal:
                    !this.state?.showAddEditSubCategoryModal,
                });
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    id="outlined-basic"
                    label={i18n.t("name")}
                    variant="outlined"
                    value={this.state?.subCategoryData?.name || ""}
                    onChange={async (e) => {
                      await this.setState({
                        subCategoryData: {
                          ...this.state.subCategoryData,
                          name: e?.target?.value,
                        },
                      });
                      await this.validateSubCategoryField("name");
                    }}
                    error={this.state?.errors?.subCategoryErrors?.subCategoryName ? true : false}
                    helperText={this.state?.errors?.subCategoryErrors?.subCategoryName}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  <FormControlLabel
                    label={i18n.t("hidden")}
                    control={
                      <Switch
                        label={i18n.t("hidden")}
                        checked={this.state?.subCategoryData?.hidden}
                        onChange={async (e) => {
                          await this.setState({
                            subCategoryData: {
                              ...this.state.subCategoryData,
                              hidden: e?.target?.checked,
                            },
                          });
                        }}
                      />
                    }
                  />
                </Typography>
              </Grid>
            </DialogContent>
            <DialogActions>
              {this.state?.editSubCategory ? (
                <Button
                  autoFocus
                  onClick={() => {
                    this.handleAddEditSubCategory("edit");
                  }}
                >
                  {i18n.t("save_changes")}
                </Button>
              ) : (
                <Button
                  autoFocus
                  onClick={async() => {
                    await this.setState({
                      errors:{
                        ...this.state.errors,
                        subCategoryErrors:{}
                      }
                    })
                    this.handleAddEditSubCategory("add");
                  }}
                >
                  {i18n.t("add_sub_category")}
                </Button>
              )}
              <Button
                autoFocus
                onClick={() => {
                  this.setState({
                    showAddEditSubCategoryModal: false,
                  });
                }}
              >
                {i18n.t("cancel")}
              </Button>
            </DialogActions>
          </DialogContainer>
          <DialogContainer
            onClose={() => [
              this.setState({
                deleteSubCategory: false,
              }),
            ]}
            fullWidth={"40%"}
            aria-labelledby="customized-dialog-title"
            open={this.state?.deleteSubCategory}
          >
            <DialogContent>
              {i18n.t("are_you_sure_you_want_to_delete_the_sub_category")}
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  this.setState({
                    deleteSubCategory: false,
                  });
                }}
              >
                {i18n.t("cancel")}
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  this.handleAddEditSubCategory("delete");
                }}
              >
                {i18n.t("yes")}
              </Button>
            </DialogActions>
          </DialogContainer>
        </React.Fragment>
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <AddEditCategorySubCategory
      {...props}
      navigate={navigate}
      params={params}
    />
  );
}
